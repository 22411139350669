import {
  AxIcon,
  AxIconButton,
  AxInputAdornment,
  AxTextField
} from '@common/modules/react/themes/components';
import { useDateTimeStyles } from '@common/modules/react/themes/components/mui-x/AxDatePicker/useDateTimeStyles';
import { styled } from '@mui/material';
import {
  LocalizationProvider,
  DesktopTimePicker as MuiTimePicker,
  type PickerValidDate as MuiPickerValidDate,
  type TimePickerProps as MuiTimePickerProps
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  forwardRef,
  type Ref
} from 'react';

export interface AxTimePickerProps<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> extends MuiTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
  required?: boolean;
}

const styledOptions = {
  name: 'AxTimePicker'
};

const StyledAxTimePicker = styled(MuiTimePicker, styledOptions)(() => {
  // AxTimePicker uses different components (slots) for the text field and input adornment. The styles are defined within the components.
  return {};
});

/**
 * The Time Picker component lets the user select a time.
 *
 * ### Links
 * - • [MUI Demo](https://mui.com/x/react-date-pickers/time-picker/)
 * - • [MUI API](https://mui.com/x/api/date-pickers/time-picker/)
 */
export const AxTimePicker = forwardRef(<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
>(
    {
      slots = {},
      slotProps = {},
      format = 'hh:mm a',
      required = false,
      ...TimePickerProps
    }: AxTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
    ref: Ref<HTMLDivElement>
  ) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale='userLocale'>
      <StyledAxTimePicker
        format={ format }
        { ...TimePickerProps }
        slots={{
          textField: AxTextField,
          inputAdornment: AxInputAdornment,
          openPickerButton: AxIconButton,
          openPickerIcon: AxIcon,
          ...slots
        }}
        slotProps={{
          ...slotProps,
          textField: {
            required: required,
            ...(slotProps?.textField ?? {})
          },
          openPickerButton: {
            size: 'medium',
            ...(slotProps?.openPickerButton ?? {})
          },
          openPickerIcon: {
            className: 'icon-time',
            ...(slotProps?.openPickerIcon ?? {})
          },
          layout: {
            sx: {
              ...useDateTimeStyles(),
              ...slotProps?.layout?.sx
            }
          }
        }}
        ref={ ref }
      />
    </LocalizationProvider>
  );
});

export default AxTimePicker;
