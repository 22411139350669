import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import I18n from '@common/libs/I18n';
import {
  AxIconButton,
  AxTooltip,
  type AxIconButtonProps,
  type AxTooltipProps,
  type IconNames
} from '@common/modules/react/themes/components';
import { ClickAwayListener } from '@mui/material';
import { useState } from 'react';


export type AccessibleTooltipButtonProps = {
  tooltipProps: MakeKeyOptional<AxTooltipProps, 'children'>,
  buttonProps: AxIconButtonProps & {
    /**
     * The name of the icomoon class icon to display.
     */
    iconName?: IconNames;
    /**
     * Override or extend the styles applied to the component.
     * @deprecated Use the `iconName` prop for icon selection. In case your icon name is wrapped in a CSS class, you can pass it here.
     */
    className?: string;
  }
};
/**
 * **💡 Note**: This component uses brand colors
 */
export default function AccessibleTooltipButton(props: AccessibleTooltipButtonProps) {
  const {
    tooltipProps,
    buttonProps = {}
  } = props;

  const [open, setOpen] = useState(false);

  const handleIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    buttonProps?.onClick?.(event);
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    tooltipProps?.onClose?.(new Event('click'));
    setOpen(false);
  };

  const {
    className = '',
    iconName = 'icon-info_sign',
    sx,
    ...otherButtonProps
  } = buttonProps;

  const iconClass = joinClassNames(className, iconName);

  return (
    <ClickAwayListener onClickAway={ handleTooltipClose } mouseEvent='onMouseDown'>
      <AxTooltip
        aria-label={ I18n.t('accessibility.tooltipInstructions') }
        placement='top'
        { ...tooltipProps }
        open={ open }
        onClose={ handleTooltipClose }
        //These are important to disable to preserve the click handler effects
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <AxIconButton
          size='medium'
          variant='ghost-link-branded'
          className={ iconClass }
          sx={{
            fontSize: 'var(--ax-tooltip-button-size)',
            ...sx
          }}
          { ...otherButtonProps }
          onClick={ handleIconButtonClick }
        />
      </AxTooltip>
    </ClickAwayListener>
  );
}
