const _ = require('underscore');

const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const AssessmentType = require('@common/data/enums/AssessmentType');
const ProgramType = require('@training/apps/training/enums/ProgramType');
const { getDueDateString } = require('@common/components/cardMetadata/MetadataStringHelper');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const AxSpacingEnum = require('@common/data/enums/AxSpacingEnum');

require('@common/libs/behaviors/card/Card');

const AssessmentTypeIconFormatter = require('@training/apps/training/assessmentTitle/AssessmentTypeIconFormatter');

class AssessmentTopicItemView extends UIKit.View {
  className() {
    return 'topic-tile qa-topic-tile ax-grid__col--12 ax-grid__col--l-6';
  }

  events() {
    return {
      'click .js-retake-button': '_onClickRetakeButton',
      'click .js-start-next-button': '_onClickStartNextButton'
    };
  }

  _onClickRetakeButton(e) {
    if ( _.isFunction(this.options?.retakeCallback)) {
      this.options.retakeCallback(e);
    }
  }

  _onClickStartNextButton(e) {
    if (_.isFunction(this.options?.startNextCallback)) {
      this.options.startNextCallback(e);
    }
  }

  behaviors() {
    return {
      Card: {}
    };
  }

  regions() {
    return {
      printCertificationsButtonWrapper: '.print-certifications-button-wrapper'
    };
  }

  constructor(options = {}) {
    super(options);

    _.defaults(this.options, {
      showIcon: false,
      buttons: []
    });

    this.template = _.tpl(require('@training/apps/training/templates/assessments/AssessmentTopicItemView.html'));
  }

  initialize() {
    // Server added the program object to the assessment results, if available.
    // Client can access passingGrade from "program". If not available, the client will see "program": null.
    // So need the null check.
    const lastRelevantResult = this.model.get('lastRelevantResult');

    if (lastRelevantResult) {
      const assessmentType = lastRelevantResult.type;
      this.programType = lastRelevantResult.program && lastRelevantResult.program.programType;

      if ((this.programType === ProgramType.Reinforcement || this.programType === ProgramType.AssignedPath) && assessmentType === AssessmentType.CertificationTraining) {
        this.passingGrade = lastRelevantResult.program.certPassingGrade;
      } else if (this.programType === ProgramType.Certification) {
        this.passingGrade = lastRelevantResult.program.passingGrade;
      // FormalExamTraining does not really have the same type of lastRelevantResult as the other training types.
      // TODO - Change the condition below once we address this discrepancy at some point
      } else if (assessmentType === AssessmentType.FormalExamTraining) {
        this.passingGrade = lastRelevantResult.passingGrade;
      }
    }

    // If programs passingGrade was null set the value to the models passing gradde
    if (this.passingGrade == null) {
      const DEFAULT_PASSING_GRADE = TenantPropertyProvider.get().getProperty('introTrainingPassingGrade');
      this.passingGrade = this.model.get('passingGrade') || DEFAULT_PASSING_GRADE;
    }
  }

  render() {
    this.$el.html(
      this.template(
        $.extend(true, {}, this.model.toJSON())
      )
    );

    if (this.options.showIcon) {
      this._renderIcon();
    }
    this._renderContentString();
    this._renderDueDateString();
    this._renderRewardPointsString();

    const inProgressAssessmentGetter = this.model.get('inProgressAssessmentGetter');
    if (_.isFunction(inProgressAssessmentGetter) && inProgressAssessmentGetter() != null) {
      this._renderInProgressAssessment();
    } else {
      this._renderLastResult();
    }

    const lastRelevantResult = this.model.get('lastRelevantResult');
    if (TenantPropertyProvider.get().getProperty('isPrintCertificationsEnabled') && lastRelevantResult?.type === AssessmentType.CertificationTraining && lastRelevantResult?.passed) {
      this.renderPrintCertificationsButton()
    }

    this._showButtons();
    this._renderLocked();

    return this;
  }

  _renderLastResult() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    let resultClass;

    if (lastRelevantResult != null) {
      resultClass = this._getResultClass(lastRelevantResult);

      this._renderPassedState(lastRelevantResult, resultClass);
      this._renderGrade(lastRelevantResult, resultClass);
    }

    this._renderAriaLabel(resultClass);
  }

  renderPrintCertificationsButton() {
    const printCertificationButton = ViewControllerFactory.createLegacyView(require('@common/modules/react').ReactControllerDefinitionFactory({
      component: import('@training/apps/topics/printCertifications/PrintCertificationsButtonContextWrapper'),
      props: {
        user: window.apps.auth.session.user,
        buttonVariant: 'branded',
        buttonSx: { mb: AxSpacingEnum.REM_M },
        topicId: this.model.get('topic').id,
        level: this.model.get('level')
      }
    }));

    this.printCertificationsButtonWrapper.show(printCertificationButton);
  }

  _showButtons() {
    if (this.options.isRetake && this.options.retakeCallback) {
      this.$('.js-retake-button').toggleClass('hidden', false);
    } else if (this.options.showStartNext && this.options.startNextCallback) {
      this.$('.js-start-next-button').toggleClass('hidden', false);
    }
  }

  _getResultClass(lastRelevantResult) {
    if (lastRelevantResult.passed) {
      return 'pass';
    }

    return 'fail';
  }

  _renderAriaLabel(resultClass, name = this.model.get('topic').name) {
    let ariaLabel;

    if (resultClass) {
      ariaLabel = name + ' ' + I18n.t(`assessments.item.grade.text.${ resultClass }`);
    } else if (this.model.get('locked')) {
      ariaLabel = name + ' ' + I18n.t('assessments.item.locked');
    } else {
      ariaLabel = name;
    }

    this.$el.attr('aria-label', ariaLabel);
  }

  _renderPassedState(lastRelevantResult, resultClass) {
    this.$el.addClass(resultClass);
  }

  _renderGrade(lastRelevantResult, resultClass) {
    if (this.passingGrade > 0 && this.model.doesSupportGrading()) {
      const grade = lastRelevantResult.score != null
        ? lastRelevantResult.score
        : Math.round((lastRelevantResult.correctAnswerCount / Math.max(1, lastRelevantResult.totalAnswerCount)) * 100);

      if (lastRelevantResult.totalAnswerCount !== 0) {
        this.$('.grade-value').text(I18n.t('assessments.item.grade.value', {grade}));
      }

      this.$('.grade-text').text(I18n.t(`assessments.item.grade.text.${ resultClass }`));
    }
  }

  _renderLocked() {
    if ((this.model.get('lastRelevantResult') == null) && this.model.get('locked')) {
      this.$el.addClass('locked');
      this.$('.lock-icon').attr({
        'aria-label': I18n.t('assessments.item.locked'),
        role: 'img'
      });
    }
  }

  _renderIcon() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    const assessmentType = lastRelevantResult ? lastRelevantResult.type : this.model.getForAssessmentType();

    if (!assessmentType) {
      return;
    }

    const {
      iconClass,
      iconLabel
    } = AssessmentTypeIconFormatter(assessmentType);

    this.$('.topic .icon')
      .addClass(iconClass)
      .removeClass('hidden')
      .attr('aria-label', iconLabel);
  }

  _renderContentString() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    const $contentStringDiv = this.$('.tile-meta .content');

    const getStringGivenResult = (() => {
      if (lastRelevantResult != null) {
        const lastScore = {
          correct: lastRelevantResult.correctAnswerCount,
          total: lastRelevantResult.totalAnswerCount
        };

        return lastScore.total === 0 ? '' : I18n.t('assessments.item.lastScore', lastScore);

      } else if (this.model.get('locked')) {
        return I18n.t('assessments.item.unlockBy', this.model.get('prereqTopic'));
      }

      const module = this.model.get('module');
      const questionCount = this.model.get('questionCount');

      if (module != null) {
        const trainingStr = I18n.t(`assessments.item.content.training.${ module.moduleType }`);

        if (questionCount === 1) {
          return I18n.t('assessments.item.content.questions.singularWithTraining', {trainingStr});
        } else if (questionCount > 1) {
          return I18n.t('assessments.item.content.questions.pluralWithTraining', {
            count: questionCount,
            trainingStr
          });
        }

        return trainingStr;
      }

      if (questionCount === 1) {
        return I18n.t('assessments.item.content.questions.singular');
      } else if (questionCount > 1) {
        return I18n.t('assessments.item.content.questions.plural', {count: questionCount});
      }

      return '';
    });

    const contentString = getStringGivenResult();
    $contentStringDiv.attr('title', contentString);
    $contentStringDiv.text(contentString);
  }

  _renderDueDateString() {
    const $dueByDiv = this.$('.tile-meta .due');
    const dueDate = getDueDateString(this.model.get('dueDate'));

    if (dueDate != null) {
      $dueByDiv.text(dueDate.string);
      this.$el.toggleClass('overdue', dueDate.isOverdue);
    } else {
      $dueByDiv.hide();
    }
  }

  _renderRewardPointsString() {
    const lastRelevantResult = this.model.get('lastRelevantResult');
    const points = this.model.get('pointsForPass');
    const $rewardStringDiv = this.$('.tile-meta .rewards');

    if (lastRelevantResult != null) {
      if (lastRelevantResult.passed) {
        if ((lastRelevantResult.type === AssessmentType.RefresherTraining) || (points === 0)) {
          $rewardStringDiv.hide();
        } else {
          $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.earned', {points}));
        }
      } else {
        $rewardStringDiv.text(I18n.t('assessments.item.passGrade', {grade: this.passingGrade}));
      }
    } else if (points === 0) {
      $rewardStringDiv.hide();
    } else {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.possible', {points}));
    }
  }

  minimize() { /* nomnomnom */ }
}

module.exports = AssessmentTopicItemView;
